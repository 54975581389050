<template>
  <div class='apply'>
    <h2>布草申领</h2>
    <div class="center">
      <div class="content">
        <el-row class="descriptions">
          <el-col :span="7">酒店名称</el-col>
          <el-col :span="17">{{ siteName }}</el-col>
          <el-col :span="7">申领日期</el-col>
          <el-col :span="17">
            <div @click="showDatePicker">{{ $moment(currentDate).format('YYYY-MM-DD') }}</div>
          </el-col>
          <el-col :span="7">申领楼层</el-col>
          <el-col :span="17">{{ floor }}</el-col>
          <el-col :span="7">申领人</el-col>
          <el-col :span="17">
            <el-input v-model="name" placeholder="请输入申领人姓名" :disabled="flag"></el-input>
          </el-col>
        </el-row>
        <el-row class="title" style="display: flex;justify-content: space-between;">
          <el-col :span="9">品名</el-col>
          <el-col :span="5" v-if="config !== false">最大值</el-col>
          <el-col :span="10">申领数</el-col>
        </el-row>
        <el-row class="list" style="display: flex;justify-content: space-between;" v-for="item in applyBOList"
          :key="item.id">
          <el-col :span="9">{{ item.linenTypeName ? item.linenTypeName : '-' }}</el-col>
          <el-col :span="5" v-if="config !== false">{{ item.applyMaxAmount ? item.applyMaxAmount : '-' }}</el-col>
          <el-col :span="10">
            <el-input-number :step="1" style="text-align:center" :min="0" :precision="0" v-model="item.count"
              placeholder="输入" :disabled="flag" @change="inputNumChange(item, $event,)" />
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="buttons" v-show="disAbledBtn != '1'">
      <el-button @click="submit" :loading="submitLoading" :disabled="flag">{{ submitBtnCon }}</el-button>
      <el-button @click="empty" :disabled="flag">清空</el-button>
    </div>
    <div class="button" v-show="disAbledBtn == '1'">
      <el-button :disabled="true">已确认实发</el-button>
    </div>
    <van-popup v-model="datePickerVis" position="bottom" class="top_">
      <van-datetime-picker v-model="currentDate" type="date" title="选择日期" :min-date="minDate" :max-date="maxDate"
        @confirm="currentDateconfirm" @cancel="currentDatecancel" :formatter="formatter" @change='currentDatechange'
        v-on:touchstart.passive="onTouchStart" />
    </van-popup>
    <div class="tips" v-show="datePickerVis"> 默认12点前是当天，12点后是次日</div>
  </div>
</template>

<script>
import { putApply, getSite, getMaxLinen, getDayLinen, getConfigLinenDetail, getLinen } from '@/api'

export default {
  data() {
    return {
      name: localStorage.getItem('applyName') || '',
      applyBOList: [],
      code: '',
      submitLoading: false,
      floor: 0,
      siteName: '',
      flag: false,
      codeOld: '',
      submitBtnCon: '提交',
      disAbledBtn: null,
      config: false,
      datePickerVis: false,
      minDate: new Date(),
      maxDate: new Date((new Date() / 1000 + 86400) * 1000),
      currentDate: new Date(),
    }
  },
  created() {
    this.getDate()
    this.init()
  },
  mounted() {
  },
  watch: {
    // currentDate: function (val) {
    //   if (val) {
    //     document.getElementsByTagName('body')[0].addEventListener('touchmove', this.handler, { passive: false })
    //   } else {
    //     document.getElementsByTagName('body')[0].addEventListener('touchmove', this.handler, { passive: false })
    //   }
    // }
  },
  methods: {
    formatter(type, value) {
      if (type === 'year') {
        return `${value}年`;
      } else if (type === 'month') {
        return `${value}月`
      } else {
        return `${value}日`
      }
      // return value;
    },
    async init() {
      this.codeOld = this.$route.query.code
      this.code = this.$route.query.code.replace(/\+/g, '%2B')
      const res = await getDayLinen(this.code, this.$moment(this.currentDate).format('YYYY-MM-DD'))
      const site = await getSite(this.code)
      this.siteName = site.data.siteName
      this.floor = site.data.floorName
      const config = await getConfigLinenDetail(site.data.siteId, site.data.floorName)
      this.config = config.data
      if (config.data === false) {
        const type = await getLinen(site.data.siteId)
        if (res.data && res.data.applyDetailList && res.data.applyDetailList.length !== 0) {
          this.disAbledBtn = res.data.status
          this.submitBtnCon = '重新提交'
          this.applyBOList = type.data.map(i => {
            const data = res.data.applyDetailList.find(c => c.type == i.textileType)
            if (data !== undefined) {
              return {
                'linenTypeName': i.textileTypeName,
                'count': data.count,
                'id': i.id,
                'linenType': i.textileType
              }
            } else {
              return {
                'linenTypeName': i.textileTypeName,
                'count': undefined,
                'id': i.id,
                'linenType': i.textileType
              }
            }
          })
        } else {
          this.submitBtnCon = '提交'
          this.applyBOList = type.data.map(el => {
            return {
              'linenTypeName': el.textileTypeName,
              'id': el.id,
              'linenType': el.textileType,
              count: undefined
            }
          })
        }
      } else {
        const type = await getMaxLinen(site.data.siteId, site.data.floorName)
        if (res.data && res.data.applyDetailList && res.data.applyDetailList.length !== 0) {
          this.disAbledBtn = res.data.status
          this.submitBtnCon = '重新提交'
          this.applyBOList = type.data.map(i => {
            const data = res.data.applyDetailList.find(c => c.type == i.linenType)
            if (data !== undefined) {
              return {
                'linenTypeName': i.linenTypeName,
                'count': data.count,
                'applyMaxAmount': i.applyMaxAmount,
                'id': i.id,
                'linenType': i.linenType,
                'referenceAmount': i.referenceAmount
              }
            } else {
              return {
                'linenTypeName': i.linenTypeName,
                'count': undefined,
                'applyMaxAmount': i.applyMaxAmount,
                'id': i.id,
                'linenType': i.linenType,
                'referenceAmount': i.referenceAmount

              }
            }
          })
        } else {
          this.submitBtnCon = '提交'
          this.applyBOList = type.data.map(el => {
            return {
              ...el,
              count: undefined
            }
          })
        }

      }
    },
    async submit() {
      this.submitLoading = true
      const arr = this.applyBOList.map(el => {
        return {
          type: el.linenType,
          count: el.count ? el.count : 0
        }
      })
      let params
      if (this.name) {
        params = {
          applyUser: this.name,
          code: this.codeOld,
          applyBOList: arr.filter(el => el.count !== 0),
          applyDate: this.$moment(this.currentDate).format('YYYY-MM-DD')
        }
        localStorage.setItem("applyName", this.name)
      } else {
        params = {
          code: this.codeOld,
          applyBOList: arr.filter(el => el.count !== 0),
          applyDate: this.$moment(this.currentDate).format('YYYY-MM-DD')
        }
      }
      const res = await putApply(params)
      if (res.code == 0) {
        this.flag = true
      }
      this.init()
      this.submitLoading = false
      this.closePage()
    },
    empty() {
      this.applyBOList.forEach(key => key.count = 0)
      this.name = ''
    },
    // 申领成功给提示关闭页面
    closePage() {
      this.$router.push({
        name: 'Applyed',
        query: { type: 'linen' }
      })
    },
    inputNumChange(a, b,) {
      // console.log(a,b);
      if (this.config !== false) {
        const name = a.linenTypeName
        if (b > a.applyMaxAmount) {
          this.$confirm(`已达到该楼层${name}申领的最大需求量，请核对确认数量！`, '', {
            confirmButtonText: '确定',
            showCancelButton: false,
            customClass: 'warningBox'
          }).then(() => {
            this.applyBOList.map(i => {
              if (i.linenType === a.linenType) {
                i.count = a.applyMaxAmount
              }
              return i
            })

          }).catch(() => {
            this.applyBOList.map(i => {
              if (i.linenType === a.linenType) {
                i.count = a.applyMaxAmount
              }
              return i
            })
          })
        }
      }
    },
    showDatePicker() {
      this.datePickerVis = true
    },
    currentDatechange() {
      // this.init()

    },
    currentDateconfirm() {
      this.disAbledBtn=null
      this.init()
      this.datePickerVis = false
    },
    currentDatecancel() {
      this.getDate()
      this.datePickerVis = false
    },
    onTouchStart(event) {
      event.preventDefault();
    },
    getDate() {
      const currentTime = this.$moment();
      const noonTime = this.$moment().hour(12).minute(0).second(0);
      if (currentTime.isAfter(noonTime)) {
        this.currentDate = new Date((new Date() / 1000 + 86400) * 1000)
      } else {
        this.currentDate = new Date()
      }
    }
  }
};
</script>
<style lang='scss'>
.warningBox {
  .el-message-box__header {
    display: flex;
    justify-content: center;
    padding: 10px 0 0 0;

    .el-message-box__headerbtn {
      display: none;
    }
  }

  .el-message-box__header::before {
    content: '';
    background: url('~@/assets/warning.png') no-repeat;
    width: 35px;
    height: 35px;
    display: inline-block;
    background-size: 100% 100%;
  }

  .el-message-box__content {
    font-size: 20px;
  }

  .el-message-box__btns {
    text-align: center;

    .el-button--primary {
      font-weight: bolder;
      width: 100%;
      background-color: #fff;
      font-size: 18px;
      color: #2752ff;
      border: none;
    }
  }
}

.successBox {
  .el-message-box__btns {
    text-align: center;
    padding: 20px 60px 0 60px;

    .el-button {
      width: 100%;

      span {
        font-size: 18px !important;
      }
    }
  }

  .el-message-box__content {
    display: flex;
    justify-content: center;

    .el-message-box__container {
      // transform: translateX(25%);
      width: fit-content;
      font-size: 18px;

      p {
        width: fit-content;
        font-size: 22px;
      }
    }
  }
}
</style>
<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
  line-height: 1;
}

::v-deep .top_ {
  .van-datetime-picker {
    .van-picker__toolbar {
      .van-picker__confirm {
        color: #236df5 !important;
      }
    }

    .van-picker__columns {
      [class*=van-hairline]::after{
        position: absolute;
        box-sizing: border-box;
        content: ' ';
        pointer-events: none;
        top: -50%;
        right: -50%;
        bottom: -50%;
        left: -50%;
        border-top: 1px solid #236df5 !important;
        border-bottom: 1px solid #236df5 !important;
        // -webkit-transform: scale(.5);
        transform: scale(.5);
      }

      .van-picker-column {
        .van-picker-column__wrapper {
          .van-picker-column__item {
            color: #236df5 !important;
          }
        }
      }
    }
  }

}

.tips {
  color: #ffffff;
  background: #7f7f7f;
  padding: 5px 10px;
  border-radius: 5px;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 16px;
  width: 70%;
  text-align: center;
  z-index: 10001;

}

.apply {
  background: #f8f8f8;
  min-height: 100vh;

  h2 {
    padding: 14px 0;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    background: #fff;
    text-align: center;
  }

  .center {
    padding: 0 11px;
    width: 100%;
    box-sizing: border-box;

    .content {
      background: #fff;
      padding: 0 17px 13px 17px;

      h3 {
        display: flex;
        align-items: flex-start;

        i {
          display: block;
          width: 3px;
          height: 21px;
          background: #236df5;
          border-radius: 2px;
          margin-right: 5px;
        }

        font-size: 21px;
        font-weight: bold;
        color: #333333;
      }

      ::v-deep .descriptions {
        margin: 22px 0 0 6px;

        .el-col {
          font-size: 18px;
          font-weight: 500;
          color: #666666;
          margin-bottom: 16px;
        }

        .el-col-17 {
          color: #333333;

          .el-input {
            margin-top: -4px;

            .el-input__inner {
              border: none;
              line-height: 1;
              padding: 0;
              font-size: 18px;
              font-weight: 500;
              color: #333;
              height: inherit;
              text-align: left;
            }
          }
        }
      }

      .title {
        background: #f2f5f3;

        .el-col {
          font-size: 20px;
          font-weight: bold;
          color: #236df5;
          text-align: center;
          padding: 11px 0 13px;
        }
      }

      ::v-deep .list {
        // padding: 14px 0 17px;
        border-bottom: 1px solid #eaeaea;
        box-sizing: border-box;
        height: 50px;
        display: flex;
        align-items: center;

        .el-col {
          font-size: 18px;
          font-weight: 500;
          color: #333333;
          text-align: center;

          .el-input-number {
            width: 100%;
            text-align: center;
            display: flex;
            justify-content: center;
          }

          .el-input {
            margin: -4px 20px 0;
            width: 66px;

            .el-input__inner {
              text-align: center;
              width: 100%;
              border: none;
              line-height: 1;
              padding: 0;
              font-size: 18px;
              font-weight: 500;
              color: #333;
              height: inherit;
            }

            .el-input__inner:focus {
              font-size: 24px;
              color: #236df5;
              border: 1px solid #236df5;
              border-radius: 4px;
            }

            .el-input__inner::placeholder {
              font-size: 18px;
            }
          }

          .el-input-number__increase,
          .el-input-number__decrease {
            border: none;
            background-color: #236df5;
            color: #fff;
            width: 20px;
            height: 20px;
            text-align: center;
            border-radius: 4px;

            i {
              line-height: 20px;
              text-align: center;
            }

          }

          .el-input-number__decrease {
            left: 10px;
          }

          .el-input-number__increase {
            right: 0;
            left: 103px;
          }
        }

        .el-col-5 {
          font-weight: bold;
        }

        .el-col-10 {
          color: #236df5;

          .el-input {
            .el-input__inner {
              font-size: 21px;
              color: #236df5;
              // font-weight: bold;
            }
          }
        }
      }
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 15px 11px;

    .el-button {
      display: inline-block;
      padding: 14px 42px;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      background: #236df5;
      border-radius: 22px;
    }

    .el-button:last-child {
      background: #cacaca;
    }
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 15px 11px;

    .el-button {
      display: inline-block;
      padding: 14px 42px;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      background: #cacaca;
      border-radius: 22px;
      width: 100%;
    }
  }

  .el-input.is-disabled .el-input__inner {
    background: none;
  }

}
</style>